<script>
import InfoCardPageForm from "@/views/menu/info-card-page/infoCardPageForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    infoCardPageService: "infoCardPageService",
    toastService: "toastService",
  }
})
export default class InfoCardPageNew extends mixins(InfoCardPageForm) {
  formId = "edit-info-card-page-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.infoCardPageService.create(this.model);
      this.$router.push("/info-card-pages/list");
      this.toastService.success(this.translations.success.infoCardPage_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.infoCardPages_new;
  }

  afterCreate () {
     this.model = {
      code: null,
      event: null,
      cardWithDescription: false,
      isCircleImage: false,
      visualizeTagOnCards: false,
      localizations: [],
      infoBoxData: {
        enableInfoBox: false,
        code: "",
        width: null,
        height: null,
        x: null,
        y: null,
        localizations: [],
      }
    };
    this.isReady = true;
  }
}
</script>
